class TagsManager {
  constructor( selector ) {
    this.selectors = document.querySelectorAll( selector );
    this.calculateTags();
    this.debouncedCalculateTags = this.debounce( this.calculateTags.bind( this ), 100 );
    window.addEventListener( 'resize', this.debouncedCalculateTags );
    document.addEventListener( 'facetwp-loaded', function() {
      new TagsManager( '.e-stn--limit-taxonomy .e-mtf--taxonomy' ); //NOSONAR
    } );
  }

  calculateTags() {
    this.selectors.forEach( container => {
      let totalTagsWidth = 0;
      const tags = container.querySelectorAll( 'span, a' );
      const tagsWrapperWidth = container.offsetWidth - 50;
      let hideTagCount = 0;

      // remove more tag div
      container.querySelector( '.more-tags' )?.remove();

      const moreTagsDiv = document.createElement( 'div' );
      moreTagsDiv.classList.add( 'tag' );
      moreTagsDiv.classList.add( 'more-tags' );

      if( tags.length > 3 ){
        tags.forEach( tag => {
          tag.classList.remove( 'hide' );
          totalTagsWidth += tag.offsetWidth;
          if ( totalTagsWidth > tagsWrapperWidth ) {
            tag.classList.add( 'hide' );
            hideTagCount++;
          }
        } );
      }

      if ( hideTagCount > 0 ) {
        moreTagsDiv.textContent = '...';
        container.append( moreTagsDiv );
      }
    } );
  }
  debounce( func, wait ) {
    let timeout;
    return function( ...args ) {
      clearTimeout( timeout );
      timeout = setTimeout( () => func.apply( this, args ), wait );
    };
  }
}

const tagsManager = new TagsManager( '.e-stn--limit-taxonomy .e-mtf--taxonomy' );
document.addEventListener( 'DOMContentLoaded', tagsManager );
