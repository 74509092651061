const globalSearchPopup = {
  searchIcon: document.querySelector( '.menu-search-icon .mega-menu-title' ),
  closeIcon: document.querySelector( '.mega-search-wrap .search-close' ),
  searchField: document.querySelector( 'header input.search-input' ),

  /**
   * Open search popup
   */
  openPopup: function() {
    document.body.classList.add( 'search-popup-opened' );
    this.setPlaceholder();
    this.searchField.focus();
  },

  /**
   * Close search popup
   */
  closePopup: function() {
    document.body.classList.remove( 'search-popup-opened' );
  },

  setPlaceholder: function() {
    this.searchField.setAttribute( 'placeholder', this.searchField.getAttribute( 'data-placeholder' ) );
  },

  init: function() {
    if ( !this.searchIcon ) return;

    this.searchIcon.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      this.openPopup();
    } );

    this.closeIcon.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      this.closePopup();
    } );
  }
};
globalSearchPopup.init();
