document.addEventListener( 'DOMContentLoaded', () => {
  const nextButton = document.querySelector( '.e-stn--video-testimonial .swiper-button-next' );
  const prevButton = document.querySelector( '.e-stn--video-testimonial .swiper-button-prev' );

  if ( nextButton && prevButton ) {
    const buttonWrapper = document.createElement( 'div' );
    buttonWrapper.classList.add( 'swiper-btn-wrapper' );
    buttonWrapper.appendChild( nextButton );
    buttonWrapper.appendChild( prevButton );

    const rightContentBlock = document.querySelector( '.e-stn--video-testimonial .e-div--right-wrapper' );
    if ( rightContentBlock ) {
      rightContentBlock.appendChild( buttonWrapper );
    }
  }
} );
