import CryptoJS from 'crypto-js';

document.addEventListener( 'DOMContentLoaded', () => {
  const hasClosed = localStorage.getItem( 'E25-Notification-Closed' );
  const currentText = document.querySelector( '.b3rg-notification__text .e-btn__text' )?.textContent;
  const body = document.querySelector( 'body' );
  let isContentChanged = false;

  function getB3rgNotificationCookies() {
    let cookies = document.cookie.split( '; ' );
    let filteredCookies = {};

    cookies.forEach( cookie => {
      let [key, value] = cookie.split( '=' );
      if ( key.startsWith( 'b3rg_notification_' ) ) {
        filteredCookies[key] = decodeURIComponent( value );
      }
    } );

    return filteredCookies;
  }

  if( currentText ) {
    let hashContent = CryptoJS.MD5( currentText ).toString();

    // Get all notification cookies
    let notificationCookies = getB3rgNotificationCookies();

    // Check if the hash is different from stored cookies
    let hasMatchingCookie = Object.values( notificationCookies ).some( value => value === hashContent );

    if ( !hasMatchingCookie ) {
      isContentChanged = true;
    }
  }
  if ( hasClosed && !isContentChanged ) {
    body.classList.add( 'notification-closed' );
  } else {
    body.classList.add( 'notification-added' );
  }

  let closeButtons = document.querySelectorAll( '.b3rg-notification .b3rg-notification__close' );

  closeButtons.forEach( ( closeButton ) => {
    closeButton.addEventListener( 'click', ( event ) => {
      // Disable the close button
      event.target.disabled = true;

      // Removes the notification
      event.target.closest( '.b3rg-notification' ).remove();
      localStorage.setItem( 'E25-Notification-Closed', 'true' );

      const notificationId = event.target.getAttribute( 'data-notification-id' );
      const xhr = new XMLHttpRequest();
      xhr.open( 'POST', b3rgScriptData.ajaxurl, true ); // eslint-disable-line no-undef
      xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
      xhr.onload = () => {
        if ( xhr.status >= 200 && xhr.status < 400 ) {
          event.target.closest( '.b3rg-notification' )?.remove();
        } else {
          // We reached our target server, but it returned an error
          console.error( xhr.responseText );
        }
        // Re-enable the close button
        event.target.disabled = false;
      };
      xhr.onerror = () => {
        // There was a connection error of some sort
        console.error( 'Connection error occurred' );
        // Re-enable the close button
        event.target.disabled = false;
      };
      xhr.send( encodeURI( 'action=b3rg_create_notification_cookie&notification_id=' + notificationId ) );
    } );
  } );
} );
