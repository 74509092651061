document.addEventListener( 'click', ( e ) => {
  if ( e.target.classList.contains( 'facetwp-page' ) || e.target.classList.contains( 'page-numbers' ) ) {
    const adminBar = document.getElementById( 'wpadminbar' );
    const header = document.querySelector( 'header' );
    const headerHeight = header ? header.offsetHeight : 0;
    const totalHeaderHeight = headerHeight + ( adminBar ? adminBar.offsetHeight : 0 );
    const facetwp = document.querySelector( '.facetwp-template' );
    const facetwpTop = facetwp.getBoundingClientRect().top;
    const currentPos = window.scrollY;

    let currentElement = e.target;
    while ( currentElement && currentElement.tagName !== 'SECTION' ) {
      currentElement = currentElement.parentElement;
    }

    if ( currentElement ) {
      window.scrollTo( {
        top: currentPos + ( facetwpTop-totalHeaderHeight - 103 ),
        behavior: 'smooth'
      } );
    }
  }
} );
