const tabContentCards = {
  accs: document.querySelectorAll( '.e-acc--tabs-with-content-cards' ),

  init: () => {
    if ( tabContentCards.accs.length === 0 ) return;
    tabContentCards.accs.forEach( accordion => {
      tabContentCards.setProgressBar( accordion );
      tabContentCards.addClickListener( accordion ); 
    } );
  },

  /**
   * Set accordion progress bar animation
   * @param {Element} acc 
   */
  setProgressBar: ( acc ) => {
    const settings = JSON.parse( acc.getAttribute( 'data-settings' ) );
    if ( settings.autoSlide === true ) {
      acc.querySelectorAll( '.e-acc__itm' )?.forEach( item => {
        const line = document.createElement( 'span' );
        line.classList.add( 'progress-line' );
        line.style.animationDuration = `${settings.autoPlayInterval}ms`;
        item.appendChild( line );
      } );
    }
  },

  /**
 * Set animation duration for .progress-line
 * @param {Element} acc 
 */
  setLineAnimatorDuration: ( acc, duration ) => {
    const lineAnimators = acc.querySelectorAll( '.e-acc__itms-wrpr .progress-line' );
    if ( lineAnimators.length > 0 ) {
      lineAnimators.forEach( lineAnimator => {
        lineAnimator.style.animationDuration = `${duration}ms`;
      } );
    }
  },

  /**
   * Add click listener to elements with the specified selector
   * @param {Element} acc 
   */
  addClickListener: ( acc ) => {
    const wrapper = acc.querySelector( '.e-acc__itms-wrpr' );
    if ( wrapper ) {
      wrapper.addEventListener( 'click', () => {
        tabContentCards.setLineAnimatorDuration( acc,0 );
      } );
    }
  }
};

document.addEventListener( 'DOMContentLoaded', () => {
  if ( window.innerWidth > 992 ) {
    tabContentCards.init();
  }
} );
