class MobileBannerNav {
  constructor ( section ) {
    this.section = section;
  }

  cloneActiveMenu() {
    const navWrapper = this.section?.querySelector( '.wp-block-navigation__container' );
    const activeNav = navWrapper?.querySelector( '.current-menu-item' );
    const cloneActiveNav = activeNav?.cloneNode( true );
    cloneActiveNav?.classList.add( 'active-nav-item' );
    this.clickMenu( cloneActiveNav );
    navWrapper?.prepend( cloneActiveNav );
  }

  clickMenu( activeNav ) {
    const openMenu = this.section?.querySelector( '.wp-block-navigation__responsive-container-open' );
    const closeMenu = this.section?.querySelector( '.wp-block-navigation__responsive-container-close' );
    let isMenuOpen = false;
    activeNav?.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      if ( isMenuOpen ) {
        closeMenu.click();
        isMenuOpen = false;
      } else {
        openMenu.click();
        isMenuOpen = true;
      }
    } );
  }
}

const bannerNav = document.querySelector( '.e-stn--banner-nav' );
const mobileBannerNav = new MobileBannerNav( bannerNav );
document.addEventListener( 'DOMContentLoaded', mobileBannerNav.cloneActiveMenu() );
