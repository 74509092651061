document.addEventListener( 'DOMContentLoaded', () => {
  const section = document.querySelector( '.e-stn--topic-accordion' );  
  if ( section ) {
    const items = section.querySelectorAll( '.e-acc__itm-wrpr' );
    const loadMoreButton = section.querySelector( '.e-btn--topic-acc-load-more' );
  
    // Hide items if there are more than 5
    if ( items.length > 5 ) {
      items.forEach( ( item, index ) => {
        if ( index >= 5 ) {
          item.style.display = 'none';
        }
      } );
    }
  
    // Show hidden items when the "Load More" button is clicked
    if ( loadMoreButton ) {
      loadMoreButton.addEventListener( 'click', ( event ) => {
        event.preventDefault(); // Prevent the default action of the button
  
        items.forEach( item => {
          item.style.display = ''; 
        } );
        loadMoreButton.style.display = 'none';
      } );
    }
  }
} );
  