class LeadershipTabs {
  constructor( section ) {
    this.section = document.querySelector( section );
    if ( !this.section ) return;

    this.buttons = this.section?.querySelectorAll( '.e-div--button-wrapper .e-btn' );
    this.teamSections = this.section?.querySelectorAll( '.facetwp-template-static' );

    this.buttons[0]?.classList.add( 'active' );
    this.teamSections[0]?.parentElement.classList.add( 'active' );
    
    this.addClickEventListeners();
  }

  addClickEventListeners() {
    this.buttons.forEach( ( button, index ) => {
      button.addEventListener( 'click', ( event ) => {
        event.preventDefault();
        this.updateActiveTab( index );
      } );
    } );
  }

  updateActiveTab( activeIndex ) {
    this.buttons.forEach( ( button, index ) => {
      if ( index === activeIndex ) {
        button.classList.add( 'active' );
        this.teamSections[index].parentElement.classList.add( 'active' );
      } else {
        button.classList.remove( 'active' );
        this.teamSections[index].parentElement.classList.remove( 'active' );
      }
    } );
  }
}

const leadershipTabs = new LeadershipTabs( '.e-stn--leadership-tabs' );
document.addEventListener( 'DOMContentLoaded', leadershipTabs );
