class ParallaxMove {
  constructor( element, options ) {
    this.element = element;
    this.settings = Object.assign( {
      offset: 0,
      mobile: false,
      dir: 'top',
      headerFixed: false,
      speedFactor: 300,
    }, options );
    this.init();
  }
  
  init() {
    window.addEventListener( 'load', this.updatePosition.bind( this ) );
    window.addEventListener( 'scroll', this.updatePosition.bind( this ) );
    window.addEventListener( 'resize', this.updatePosition.bind( this ) );
  }
  
  updatePosition() {
    const wH = window.innerHeight;
    const wW = window.innerWidth;
    const hH = document.querySelector( 'header' ).offsetHeight;
    const parent = this.element.closest( 'section' );
    const boundingBox = parent.getBoundingClientRect();
    const parentTop = boundingBox.top;
    const parentBottom = boundingBox.bottom;
    const speed = this.settings.headerFixed
      ? this.settings.offset / ( ( wH - hH ) * this.settings.speedFactor )
      : this.settings.offset / ( wH * this.settings.speedFactor );
    const inViewHeight = wH - parentTop;
    const scrollPortion = this.settings.dir === 'top'
      ? this.settings.offset - inViewHeight * speed
      : ( this.settings.offset - inViewHeight * speed ) * -1;
    const disableParallax = wW < 992 && !this.settings.mobile;
  
    if ( parentTop <= wH + 100 && parentBottom >= 0 && !disableParallax ) {
      this.element.style.transform = `translate3d(0, ${scrollPortion}px, 0)`;
    } else if ( disableParallax ) {
      this.element.style.removeProperty( 'transform' );
    }
  
    this.element.classList.add( 'parallax-item' );
  }
}
  
document.addEventListener( 'DOMContentLoaded', () => {
  const parallaxInstances = []; // NOSONAR
  const applyParallax = ( selector, offsets, speedFactor = 1 ) => {
    const elements = document.querySelectorAll( selector );
    offsets.forEach( ( offset, index ) => {
      if ( elements[index] ) {
        parallaxInstances.push( new ParallaxMove( elements[index], {
          offset: offset,
          headerFixed: true,
          speedFactor: speedFactor
        } ) );
      }
    } );
  };
  
  if ( document.querySelector( '.e-col--image-cta--parallex-left-wrpr' ) ) {
    applyParallax( '.e-col--image-cta--parallex-left-wrpr .e-img', [200, 350, 400, 250] );
  }
  if ( document.querySelector( '.e-col--image-cta--parallex-right-wrpr' ) ) {
    applyParallax( '.e-col--image-cta--parallex-right-wrpr .e-img', [-400, -300, -200, -250] );
  }
} );
