const evntListing = {
  filterVisibilitySet: false,
  originalDom: [],

  init: function () {
    if ( this.originalDom.length === 0 ) {
      this.originalDom = Array.from( document.querySelectorAll( '.e-div--title-card' ) );
    }

    if ( this.originalDom.length === 0 ) return;

    this.originalDom.forEach( ( originalTitleCard ) => {
      const titleCard = originalTitleCard.cloneNode( true );
      const section = originalTitleCard.closest( 'section' );
      const postWrapper = section.querySelector( '.facetwp-template .b3rg-posts .row' )
        || section.querySelector( '.facetwp-template-static .b3rg-posts .row' );
      const firstElement = postWrapper?.querySelector( ':scope > .e-col' );

      if ( !firstElement || postWrapper.querySelector( '.e-div--title-card' ) ) return;

      const titleCardWrapper = document.createElement( 'div' );
      titleCardWrapper.className = firstElement.className;
      titleCard.classList.add( 'active' );
      titleCardWrapper.appendChild( titleCard );
      postWrapper.prepend( titleCardWrapper );
    } );

    if ( !this.filterVisibilitySet ) {
      this.filterVisibility();
      this.filterVisibilitySet = true;
    }
  },

  filterVisibility: function() {
    const sections = document.querySelectorAll( '.e-stn--upcoming-events' );
    if ( sections.length === 0 ) return;

    sections.forEach( section => {
      const filter = section.querySelector( '.e-col--filter-wrap' );
      const posts = section.querySelectorAll( '.b3rg-posts .e-sgp' );
      if ( posts.length === 0 ) {
        filter.classList.add( 'hide' );
      }
    } );
  }
};

document.addEventListener( 'facetwp-loaded', () => evntListing.init() );
