document.addEventListener( 'DOMContentLoaded', function () {
  const tocContainer = document.querySelector( '.ez-toc-list' );
  const leftNav = document.querySelector( '.e-div--s-o-h-left-nav' );
  const tocToggle = document.querySelector( '.ez-toc-title-container' );
  const tocNav = document.querySelector( '.e-div--s-o-h-left-nav-nav-wrap' );
  const tooltips = document.querySelectorAll( '.tooltip' );
  const screenHeight = window.innerHeight - 130;

  // Function to handle leftNav position
  function adjustLeftNav() {
    if ( leftNav && leftNav.offsetHeight > screenHeight ) {
      leftNav.style.position = 'relative';
      leftNav.style.top = '40px';
    }
  }

  // Function to set active link
  function setActiveLink( clickedLink ) {
    tocContainer.querySelectorAll( 'a' ).forEach( function ( link ) {
      link.classList.remove( 'active' );
    } );
    clickedLink.classList.add( 'active' );
  }

  // Function to initialize TOC links
  function initTocLinks() {
    if ( tocContainer ) {
      tocContainer.addEventListener( 'click', function ( e ) {
        if ( e.target.tagName.toLowerCase() === 'a' ) {
          setActiveLink( e.target );
        }
      } );

      const firstLink = tocContainer.querySelector( 'a' );
      if ( firstLink ) {
        firstLink.classList.add( 'active' );
      }
    }
  }

  // Function to handle TOC toggle for mobile
  function handleTocToggle() {
    function isMobile() {
      return window.innerWidth <= 992;
    }

    tocToggle?.addEventListener( 'click', function () {
      if ( isMobile() ) {
        tocNav?.classList.toggle( 'active' );
      }
    } );

    window.addEventListener( 'resize', function () {
      if ( !isMobile() ) {
        tocNav?.classList.remove( 'active' );
        tocNav.style.maxHeight = 'none';
      } else {
        tocNav.style.maxHeight = '';
      }
    } );
  }

  // Function to initialize tooltips
  function initTooltips() {
    tooltips.forEach( function ( tooltip ) {
      const tooltipBox = tooltip.querySelector( '.tooltip-box' );

      tooltip.addEventListener( 'mouseover', function () {
        tooltipBox.style.display = 'block';
      } );

      tooltip.addEventListener( 'mouseout', function () {
        tooltipBox.style.display = 'none';
      } );

      window.addEventListener( 'resize', function () {
        if ( window.innerWidth < 600 ) {
          tooltipBox.style.left = 'initial';
          tooltipBox.style.right = '0';
        } else {
          tooltipBox.style.left = '50%';
          tooltipBox.style.right = 'initial';
          tooltipBox.style.transform = 'translateX(-50%)';
        }
      } );
    } );
  }

  // Initialize all functions
  adjustLeftNav();
  initTocLinks();
  handleTocToggle();
  initTooltips();
} );
