import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

class TimelineSlider {
  constructor( section ) {
    this.section = document.querySelector( section );
    if( this.section === null ) return;
    this.preSlideIndex = null;
    const defaultActiveIndex = ( window.innerWidth > 991 )? 2 : 0 ;

    this.navSlidesHeight = [];
    this.navSlides = this.section?.querySelectorAll( '.nav-content .tab-slide' );
    this.navSlides.forEach( ( slide ) => {
      this.navSlidesHeight.push( slide.getBoundingClientRect().height );
    } );

    this.initSlider( defaultActiveIndex );
    this.navClick();
    this.contentSliderChange();
    this.navPanelControl( defaultActiveIndex );
  }

  navPanelControl( activeIndex ) {
    const navWrapper = this.section.querySelector( '.nav-content .tabs-wrapper' );
    const scrollPos = this.targetPosCalculate( this.navSlides, activeIndex );
    
    navWrapper.style.transform = `translateY(-${scrollPos}px)`;

    this.navSlides[this.preSlideIndex]?.classList.remove( 'slide-active' );
    this.navSlides[this.preSlideIndex-1]?.classList.remove( 'slide-prev' );
    this.navSlides[this.preSlideIndex+1]?.classList.remove( 'slide-next' );
    
    this.navSlides[activeIndex]?.classList.add( 'slide-active' );
    this.navSlides[activeIndex-1]?.classList.add( 'slide-prev' );
    this.navSlides[activeIndex+1]?.classList.add( 'slide-next' );
    
    
    this.preSlideIndex = activeIndex;
  }
  
  targetPosCalculate( navSlides, activeIndex ) {
    let targetPos = 0;
    navSlides.forEach( ( slide, index ) => {
      if ( index < activeIndex ) {
        targetPos += this.navSlidesHeight[index];
      }
    } );
    return targetPos;
  }
  
  initSlider( defaultActiveIndex ) {
    this.section.querySelectorAll( '.swiper[data-swiper]' ).forEach( swiperElement => {
      const swiperConfig = JSON.parse( swiperElement.getAttribute( 'data-swiper' ) ) || {};
      new Swiper( swiperElement, swiperConfig ); // NOSONAR
    } );
    this.content = this.section.querySelector( '.tab-content .swiper' )?.swiper;
    this.content.slideTo( defaultActiveIndex, 300, true );
  }

  contentSliderChange() {
    this.content.on( 'slideChange', ( e ) => {
      this.navPanelControl( e.realIndex );
    } );
  }

  navClick() {
    this.navSlides.forEach( ( slider, index ) => {
      slider.addEventListener( 'click', ()=> {
        this.content.slideTo( index, 300, true );
      } );
    } );
  }
}

const svgAnimation = new TimelineSlider( '.e-stn--timeline-slider' );
document.addEventListener( 'DOMContentLoaded', svgAnimation );
