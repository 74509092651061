document.addEventListener( 'DOMContentLoaded', function () {
  let section = document.querySelector( '.e-stn--past-conferences' );

  if ( section ) {
    let eDiv = section.querySelector( '.e-div' );
    let postsRow = section.querySelector( '.b3rg-posts > .row' );

    if ( eDiv && postsRow ) {
      eDiv.parentNode.removeChild( eDiv );
      postsRow.prepend( eDiv );
    }
  }
} );
