function checkScroll() {
  document.body.classList.toggle( 'scrolled', window.scrollY > 1 );
}

function setMainMarginTop() {
  const header = document.querySelector( 'header > .e-div' );
  const main = document.querySelector( 'main, .entry-content' );
  if ( header && main ) {
    main.style.marginTop = -header.offsetHeight + 'px';
  }
}

function addMenuHoveredClass() {
  document.querySelector( 'body' ).classList.add( 'menu-hover' );
  document.querySelector( '.max-mega-menu' )?.classList.add( 'mega-menu-hover' );
}

function removeMenuHoveredClass() {
  document.querySelector( 'body' ).classList.remove( 'menu-hover' );
  document.querySelector( '.max-mega-menu' )?.classList.remove( 'mega-menu-hover' );
}

function appendTopMenuItemsToMain() {
  const sourceItems = document.querySelectorAll( '.e-div--top-menu .wp-block-navigation__container > li' );
  const targetContainer = document.querySelector( '.e-div--main-menu #mega-menu-primary_menu' );
  
  function isAppended( item ) {
    return Array.from( targetContainer.children ).some( child => child.textContent.trim() === item.textContent.trim() );
  }
  
  if ( window.innerWidth < 1200 ) {
    if ( targetContainer ) {
      sourceItems.forEach( item => {
        if ( !isAppended( item ) ) {
          targetContainer.appendChild( item.cloneNode( true ) );
        }
      } );
    }
  } else {
    const appendedItems = targetContainer.querySelectorAll( '.wp-block-navigation-item' );
    if ( appendedItems.length === 0 ) return;
    appendedItems.forEach( item => {
      targetContainer.removeChild( item );
    } );
  }
}

function handleDonateLink() {
  const donateBtn = document.querySelector( '.donate-btn > a' );
  const megaMenuWrap = document.querySelector( '#mega-menu-wrap-primary_menu' );
  const existingLink = document.querySelector( '.donate-link' );

  if ( window.innerWidth < 1200 ) {
    if ( !existingLink && donateBtn ) {
      const newLink = document.createElement( 'a' );
      newLink.href = donateBtn.href;
      newLink.innerText = donateBtn.innerText;
      newLink.classList.add( 'donate-link' );
      megaMenuWrap?.prepend( newLink );
    }
  } else if ( existingLink ) {
    existingLink.remove();
  }
}

function handleButtonPlacement() {
  const menu = document.querySelector( '#mega-menu-primary_menu' );
  const takeActionBtn = menu.querySelector( '.take-action-btn' );
  const donateBtn = menu.querySelector( '.donate-btn' );
  const newUlContainer = document.createElement( 'ul' );
  const newLiContainer = document.createElement( 'li' );

  newUlContainer.classList.add( 'action-buttons-container' );
  newLiContainer.appendChild( newUlContainer );

  if ( window.innerWidth < 1200 ) {
    if ( !document.querySelector( '#mega-menu-primary_menu > li > .action-buttons-container' ) ) {
      newUlContainer.appendChild( takeActionBtn );
      newUlContainer.appendChild( donateBtn );
      menu.appendChild( newLiContainer );
    }
  } else {
    const existingUlContainer = document.querySelector( '#mega-menu-primary_menu > li > .action-buttons-container' );
    if ( existingUlContainer ) {
      menu.appendChild( takeActionBtn );
      menu.appendChild( donateBtn );
      existingUlContainer.parentElement.remove(); 
    }
  }
}

function appendLanguageDropdown() {
  const languageDropdown = document.querySelector( '.wpml-ls-legacy-dropdown' );
  const actionButtonsContainer = document.querySelector( '.action-buttons-container' );

  if ( languageDropdown && actionButtonsContainer && !document.querySelector( '.action-buttons-container .wpml-ls-legacy-dropdown' ) ) { //eslint-disable-line max-len
    const newLi = document.createElement( 'li' );
    newLi.appendChild( languageDropdown.cloneNode( true ) );
    actionButtonsContainer.appendChild( newLi );
  }
}

function toggleHeaderClassOnSubMenuToggle() {
  const header = document.querySelector( 'header' );
  const anySubMenuExpanded = document.querySelector( '.mega-menu-item.mega-toggle-on' );

  if ( anySubMenuExpanded ) {
    header.classList.add( 'sub-menu-enabled' );
  } else {
    header.classList.remove( 'sub-menu-enabled' );
  }
}

let originalSubMenus = new Map();

function handleMenuPositioning() {
  const windowWidth = window.innerWidth;
  windowWidth > 1199 ? moveSubMenuToSecondColumn() : restoreSubMenusToOriginalPosition();
}

function moveSubMenuToSecondColumn() {
  if ( !document.querySelector( '.appended-sub-menu' ) ) {
    const firstColumnChildrenItems = document.querySelectorAll( '.two-column-hover-menu .mega-sub-menu > .mega-menu-column:first-of-type .mega-sub-menu > .mega-menu-item-has-children' ); // eslint-disable-line max-len
    const secondColumn = document.querySelector( '.two-column-hover-menu .mega-sub-menu > .mega-menu-column:nth-of-type(2)' ); // eslint-disable-line max-len
    let currentActiveMenuItem = null;

    firstColumnChildrenItems.forEach( ( menuItem, index ) => {
      const subMenu = Array.from( menuItem.children ).find( child => child.classList.contains( 'mega-sub-menu' ) );
      if ( subMenu && !originalSubMenus.has( menuItem ) ) {
        originalSubMenus.set( menuItem, subMenu );
        const newSubMenuWrapper = document.createElement( 'div' );
        newSubMenuWrapper.classList.add( 'appended-sub-menu' );
        newSubMenuWrapper.appendChild( subMenu );
        secondColumn.appendChild( newSubMenuWrapper );

        if ( index === 0 ) {
          menuItem.classList.add( 'active' );
          newSubMenuWrapper.classList.add( 'active' );
          currentActiveMenuItem = menuItem;
        }

        menuItem.addEventListener( 'mouseenter', () => {
          currentActiveMenuItem?.classList.remove( 'active' );
          document.querySelector( '.appended-sub-menu.active' )?.classList.remove( 'active' );
          menuItem.classList.add( 'active' );
          newSubMenuWrapper.classList.add( 'active' );
          currentActiveMenuItem = menuItem;
        } );
      }
    } );
  }
}

function restoreSubMenusToOriginalPosition() {
  originalSubMenus.forEach( ( subMenu, menuItem ) => {
    const appendedSubMenuWrapper = document.querySelector( '.appended-sub-menu' );
    if ( appendedSubMenuWrapper && appendedSubMenuWrapper.contains( subMenu ) ) {
      appendedSubMenuWrapper.remove();
    }
    if ( !menuItem.contains( subMenu ) ) {
      menuItem.appendChild( subMenu );
    }
    menuItem.classList.remove( 'active' );
    subMenu.classList.remove( 'active' );
  } );
  originalSubMenus.clear();
}

function updateMenuItems() {
  document.querySelectorAll( '#mega-menu-primary_menu > .mega-menu-item-has-children, .action-buttons-container > .mega-menu-item-has-children' ).forEach( item => { // eslint-disable-line max-len
    item.classList.remove( 'mega-toggle-on' );
    const subMenu = item.querySelector( '.mega-sub-menu' );
    if ( subMenu && subMenu.parentNode === item ) subMenu.style.display = 'none';
    const anchorTag = item.querySelector( 'a' );
    if ( anchorTag && anchorTag.parentNode === item ) anchorTag.setAttribute( 'aria-expanded', 'false' );
  } );
}

function moveSearchIcon() {
  const megaMenuWrap = document.querySelector( '#mega-menu-wrap-primary_menu' );
  const originalMenu = document.querySelector( '#mega-menu-primary_menu' );
  const searchIcon = document.querySelector( '.menu-search-icon' );

  if ( !searchIcon || !megaMenuWrap || !originalMenu ) return;

  const targetContainer = window.innerWidth < 1200 ? megaMenuWrap : originalMenu;
  if ( !Array.from( targetContainer.children ).includes( searchIcon ) ) {
    targetContainer.prepend( searchIcon );
  }
}

document.addEventListener( 'DOMContentLoaded', function () {
  const header = document.querySelector( 'header' );
  const menuItems = document.querySelectorAll( '.max-mega-menu > li.mega-menu-item-has-children' );
  const menuToggle = document.querySelector( '#mega-menu-wrap-primary_menu .mega-menu-toggle' );
  const menuHasChildren = document.querySelectorAll( '#mega-menu-wrap-primary_menu .mega-menu-item-has-children > a' );
  const headerInner = document.querySelector( 'header > .e-div' );

  menuItems.forEach( item => {
    item.addEventListener( 'mouseenter', addMenuHoveredClass );
    item.addEventListener( 'mouseleave', removeMenuHoveredClass );
  } );

  menuToggle.addEventListener( 'click', function() {
    setTimeout( function() {
      header.classList.remove( 'sub-menu-enabled' );
      updateMenuItems();
    }, 500 );
  } );

  menuHasChildren.forEach( menu => {
    menu.addEventListener( 'click', function() {
      setTimeout( toggleHeaderClassOnSubMenuToggle, 100 );
    } );
  } );

  if ( headerInner ) {
    const newSpan = document.createElement( 'span' );
    newSpan.textContent = 'Back';
    headerInner.appendChild( newSpan );
    newSpan.addEventListener( 'click', function() {
      header.classList.remove( 'sub-menu-enabled' );
      updateMenuItems();
    } );
  }

  checkScroll();
  appendTopMenuItemsToMain();
  handleMenuPositioning();
  handleDonateLink();
  handleButtonPlacement();
  appendLanguageDropdown();
  setMainMarginTop();
  moveSearchIcon();

  const donateLink = document.querySelector( '.donate-link' );
  if ( donateLink ) {
    donateLink.addEventListener( 'click', function( e ) {
      e.preventDefault();
      const menuToggle = document.querySelector( '.mega-menu-toggle' );
      const donateButton = document.querySelector( '.donate-btn > a' );
      menuToggle.click();
      setTimeout( () => {
        donateButton.click();
      }, 500 );
    } );
  }
} );

window.onscroll = checkScroll;

window.onresize = function() {
  checkScroll();
  appendTopMenuItemsToMain();
  handleMenuPositioning();
  handleDonateLink();
  handleButtonPlacement();
  appendLanguageDropdown();
  setMainMarginTop();
  moveSearchIcon();
};
