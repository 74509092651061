( () => {
  const filterAccordion = document.querySelector( '.e-col--filter-accordion' );
  if ( !filterAccordion ) return;

  const accordions = filterAccordion.querySelectorAll( '.e-div--filter-accordion-wrapper > .e-div' );
  accordions[0].classList.add( 'active' );

  accordions.forEach( accordion => {
    const trigger = accordion.querySelector( ':scope > p' );
    trigger.addEventListener( 'click', ( e ) => {
      e.preventDefault();
      accordion.classList.toggle( 'active' );
    } );
  } );
} ) ();
